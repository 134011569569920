import React from 'react';

import { IoMdRemove } from "react-icons/io"

export var formatCoins = (total: number) => {
    const gold = Math.floor(total / 10000);
    const silver = Math.floor(total % 10000 / 100);
    const copper = Math.floor(total % 100);

    return (
        <div className="whitespace-no-wrap">
            {gold}
            <img className="inline mr-1" style={{ height: '17px' }}
                title="g"
                src={require("../images/coin/Gold.png")}
            />
            {silver}
            <img className="inline mr-1" style={{ height: '17px' }}
                title="s"
                src={require("../images/coin/Silver.png")}
            />
            {copper}
            <img className="inline" style={{ height: '17px' }}
                title="c"
                src={require("../images/coin/Copper.png")}
            />
        </div>
    );
}


export var loading = () => {
    return (
        <div className="font-semibold uppercase tracking-wide text-center">
            <div className="inline-flex items-center text-xl">
                <IoMdRemove className="spin text-my_green-light" />
                <IoMdRemove className="spin text-my_blue-light" />
                <IoMdRemove className="spin text-my_red-light" />
            </div>
        </div>
    );
}