import React from "react"

import { APIContext } from "../../APIContext";
import { Character } from "./Character";
import { Wallet } from "./Wallet";

import { DeliveryBox } from "./DeliveryBox";
import { Overview } from "./Overview";

export default class Account extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            characters: []
        };
    }

    componentDidMount() {
        this.fetchCharacters();
    }

    render() {
      return (<div className="md:max-w-4xl xl:max-w-6xl mx-auto mb-12 mt-2 text-my_gray">

            <div className="flex">
                <div className="flex-1 ">
                  <img className="inline-block h-16" src={require("../../images/account.svg")} />
                  <p className="inline-block font-display tracking-wide text-my_blue-light text-3xl align-middle">Account</p>
                </div>
            </div>

            {!this.context.isCorrect && (
                <div className="flex justify-center">
                    <div className="card mt-6 text-center inline-block">
                        <h3 className="text-my_yellow bg-my_dark-light">API Key Required</h3>
                        <p className="font-semibold text-my_gray-dark px-4 pt-2">This page display informations about your account using an API Key.</p>
                        <p className="px-4 pb-2">You can define one by clicking on <span className="font-semibold">Enter API Key</span> in the top right corner.</p>
                    </div>
                </div>
            )}


            {this.context.isCorrect && (
                <div className="sm:block md:flex mt-2">
                    <div className="flex-auto md:w-3/6">

                        <Overview />

                        <div className="card mb-4">
                            <h3 className="text-my_yellow px-2 bg-my_dark-light">Characters</h3>
                            <div className="table w-full">
                                {this.state.isLoading && require('../utils.tsx').loading()}

                                {!this.state.isLoading && this.state.characters.map(char => (
                                    <Character character={char} />
                                ))}
                            </div>
                        </div>

                        <DeliveryBox />

                    </div>


                    <div className="flex-auto md:w-5/12 md:ml-4 sm:ml-1 mb-4">
                        <Wallet />
                    </div>
                </div>


            )}
        </div>);
    }

    private fetchCharacters = () => {
        this.setState({
            isLoading: true
        });
        if(this.context.isCorrect) {
            var key = this.context.APIKey;
            fetch("https://api.guildwars2.com/v2/characters?ids=all&access_token=" + key)
            .then(response => response.json())
            .then(data =>
                this.setState({
                    isLoading: false,
                    characters: data
                })
            );
        }
    }

}

Account.contextType = APIContext;