import React from "react"

import { APIContext } from "../../APIContext";

export class DeliveryBox extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            deliveryBox: {},
            items: []
        };
    }

    componentDidMount() {
        this.fetchDeliveryBox();
    }

    render() {
        return (
            <div className="card">
                <h3 className="text-my_yellow px-2 bg-my_dark-light">Delivery Box</h3>
                <div className="table w-full text-my_gray">
                    {this.state.isLoading && require('../utils.tsx').loading()}

                    {!this.state.isLoading && (
                    <div className="px-2">
                        {require('../utils.tsx').formatCoins(this.state.deliveryBox.coins)}
                        {this.state.items.length > 0 && this.state.items.map((item, index) => (
                            <div>
                                <img className="inline-block align-middle mr-1" style={{ height: '25px' }}
                                title={item.name}
                                src={item.icon}
                                />
                                {this.state.deliveryBox.items[index].count + " " + item.name}
                            </div>
                        ))}
                    </div>
                    )}
                </div>
            </div>
        );
    }

    private fetchDeliveryBox = () => {
        this.setState({
            isLoading: true
        });
        if(this.context.isCorrect) {
            var key = this.context.APIKey;
            fetch("https://api.guildwars2.com/v2/commerce/delivery?access_token=" + key)
            .then(responseDeliveryBox => responseDeliveryBox.json())
            .then(dataDeliveryBox => {
                var ids = "";
                for (let [key, value] of Object.entries(dataDeliveryBox.items))
                    ids += value.id + ",";

                fetch("https://api.guildwars2.com/v2/items?ids=" + ids)
                .then(responseItems => responseItems.json())
                .then(dataItems => {
                    this.setState({
                        isLoading: false,
                        deliveryBox: dataDeliveryBox,
                        items: dataItems
                    })
                })
            });
        }
    }

}

DeliveryBox.contextType = APIContext;