import React from "react"

import { APIContext } from "../../APIContext";


export class CharacterDetails extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            title: undefined,
        };
    }

    componentDidMount() {
        this.getTitle(this.props.character.title);
    }

    render() {

        const { character } = this.props;

        return (
            <div className="px-2 border-l-4 border-my_dark-light text-my_gray-dark">
                <div className="font-semibold">{character.race}, {this.state.title} lv. {character.level}</div>
                <div>Deaths: {character.deaths}</div>
            </div>
        );
    }

    private getTitle = (id: number) => {
        fetch("https://api.guildwars2.com/v2/titles/" + id)
        .then(response => response.json())
        .then(data =>
            this.setState({
                title: data.name
            })
        );
    }

}

CharacterDetails.contextType = APIContext;