import React from "react"

import { APIContext } from "../../APIContext";

export class Wallet extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            currencies: [],
            wallet: [],
            details: []
        };
    }

    componentDidMount() {
        this.fetchWallet();
    }

    render() {
        return (
            <div className="card">
                <h3 className="text-my_yellow px-2 bg-my_dark-light">Wallet</h3>
                <div className="w-full text-my_gray">
                    {this.state.isLoading && require('../utils.tsx').loading()}

                    {!this.state.isLoading && (
                    <>
                    {this.state.wallet.map((element, index) => {
                        const currency = this.getCurrencyById(element.id);
                        return (
                            <>
                                <div className={"flex hover:bg-my_dark-light cursor-pointer" + (this.state.details[index] ? " bg-my_dark-light" : "")}
                                    onClick={() => { const newDetails = this.state.details; newDetails[index] = !newDetails[index]; this.setState({ details: newDetails }); }}>
                                    <div className="flex-1">
                                        <img className="inline-block align-middle px-1" style={{ height: '25px' }}
                                        title={currency.name}
                                        src={currency.icon}
                                        />
                                        {currency.name}
                                    </div>
                                    <div className="flex-none text-right pr-1">
                                        {element.id != 1 ? element.value : require('../utils.tsx').formatCoins(element.value)}
                                    </div>
                                </div>

                                <div className={"px-2 border-l-4 border-my_dark-light text-my_gray-dark text-sm" + (this.state.details[index] ? " scale-on" : " scale-off")}>
                                    <div>{currency.description}</div>
                                </div>
                            </>)
                    })}
                    </>
                    )}
                </div>
            </div>
        );
    }

    private fetchWallet = () => {
        this.setState({
            isLoading: true
        });

        if(this.context.isCorrect) {
            var key = this.context.APIKey;
            fetch("https://api.guildwars2.com/v2/currencies?ids=all")
              .then(responseCurrencies => responseCurrencies.json())
              .then(dataCurrencies =>
                fetch("https://api.guildwars2.com/v2/account/wallet?access_token=" + key)
                  .then(responseWallet => responseWallet.json())
                  .then(dataWallet =>
                    this.setState({
                        isLoading: false,
                        currencies: dataCurrencies,
                        wallet: dataWallet,
                        details: new Array(dataWallet.length).fill(0)
                    })
                  )
              );
        }
    }

    private getCurrencyById = (id: number) => {
        return this.state.currencies.filter(
            function(data){ return data.id == id }
        )[0];
    }

}

Wallet.contextType = APIContext;