import React from "react"

import { APIContext } from "../../APIContext";

import { MdClose, MdCheck } from "react-icons/md";

export class Overview extends React.Component<any, any> {

    render() {

        const { name, commander, fractal_level, wvw_rank } = this.context.data;

        return (
            <div className="card mb-4">
                <h3 className="text-my_yellow bg-my_dark-light px-2 rounded">Overview</h3>
                <div className="flex justify-around">
                    <div className="flex flex-col items-center p-2">
                        <img className="inline-block align-middle px-1 h-10"
                        title="fractal"
                        src="https://render.guildwars2.com/file/80F608A1E8112313595813033BDEAD3C05A43D01/514379.png"
                        />
                        <span className="text-my_gray-dark font-semibold mr-1 text-sm uppercase">Fractal level</span>{fractal_level}
                    </div>
                    <div className="flex flex-col items-center p-2">
                        <img className="inline-block align-middle px-1 h-10"
                        title="commander"
                        src={require("../../images/commander_tag.png")}
                        />
                        <span className="text-my_gray-dark font-semibold mr-1 text-sm uppercase">Commander</span>
                        <span className="pt-1 text-lg">{commander ? <MdCheck className="text-my_green-light" /> : <MdClose className="text-my_red-light" /> }</span>
                    </div>
                    <div className="flex flex-col items-center p-2">
                        <img className="inline-block align-middle px-1 h-10 color-filer"
                        title="wvw_castle"
                        src="https://render.guildwars2.com/file/F0F1DA1C807444F4DF53090343F43BED02E50523/102608.png"
                        />
                        <span className="text-my_gray-dark font-semibold mr-1 text-sm uppercase">WvW rank</span>{wvw_rank}
                    </div>
                </div>
            </div>
        );
    }

}

Overview.contextType = APIContext;