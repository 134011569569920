import React from "react"
import { CharacterDetails } from "./CharacterDetails";

export class Character extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            details: false,
        };
    }

    render() {

        const { character } = this.props;

        return (
            <div>

                <div className={"block hover:bg-my_dark-light cursor-pointer w-full text-my_gray " + (this.state.details ? " bg-my_dark-light" : "")} onClick={this.toggleDetails}>

                    <div className="inline-block w-4/12 py-1">
                        <img className="inline px-1" style={{ height: '25px' }}
                            title={character.profession}
                            src={require("../../images/profession/" + character.profession + "_icon.png")}
                            />
                        <span className="font-semibold">{character.name}</span>
                    </div>

                    <div className="inline-block w-3/12">
                        {character.crafting.map(element => (
                            element.active && <>
                            <img className="inline ml-1" style={{ height: '30px' }}
                            title={element.discipline}
                            src={require("../../images/crafting/" + element.discipline + ".png")}
                            />
                            <span className="text-sm">{element.rating}</span>
                            </>
                        ))}
                    </div>

                    <div className="inline-block w-2/12 text-right">
                        {Math.floor(character.age / 3600)} h
                    </div>

                    <div className="inline-block w-3/12 text-right pr-1">
                        {new Date(character.created).toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' })}
                    </div>

                </div>

                <div className={"w-full" + (this.state.details ? " scale-on" : " scale-off")}>
                    <CharacterDetails character={character} />
                </div>



            </div>
        );
    }

    private toggleDetails = () => {
        this.setState(prevState => ({
            details: !prevState.details
        }));
    };

}
