import React from "react"
import SEO from "../components/seo";
import Account from "../components/account/Account"

const WvWPage = () => (
    <>
      <SEO title="Account" />
        <Account />
    </>
  )

export default WvWPage